<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="panel has-bg">
        <h3>{{ $t('bearings.headlineEffects') }}</h3>
        <!-- Kraft -->
        <div class="row">
          <div class="col-sm-6">
            <b-form-group
              label-for="power"
            >
              <template slot="label">
                {{ $t('bearings.power') }} <span class="text-muted">F<sub>z,d</sub></span>
              </template>
              <b-input-group append="kN">
                <FormInputDecimal
                  id="power"
                  v-model="power"
                  type="text"
                  decimal-places="1"
                >
                </FormInputDecimal>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('bearings.headlineBearingDimension') }}</h3>
        <div class="row">
          <!-- Lagerbeite -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingWidth"
            >
              <template slot="label">
                {{ $t('bearings.bearingWidth') }} <span class="text-muted">a</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="bearingWidth"
                  v-model="bearingWidth"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Lagerlänge -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingLength"
            >
              <template slot="label">
                {{ $t('bearings.bearingLength') }} <span class="text-muted">b</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="bearingLength"
                  v-model="bearingLength"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Lagerdicke -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingThickness"
            >
              <template slot="label">
                {{ $t('bearings.bearingThickness') }} <span class="text-muted">t</span>
              </template>
              <b-input-group>
                <b-form-select
                  id="bearingThickness"
                  v-model="bearingThickness"
                  :options="bearingThicknessSelectOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('bearings.headlineHoles') }}</h3>
        <div class="row">
          <div class="col-lg-6">
            <b-form-group
              label-for="holesDiameter"
            >
              <template slot="label">
                {{ $t('bearings.holesDiameter') }} <span class="text-muted">d</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="holesDiameter"
                  v-model="holesDiameter"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-lg-6">
            <b-form-group
              label-for="holesCount"
            >
              <template slot="label">
                {{ $t('bearings.holesCount') }} <span class="text-muted">n</span>
              </template>
              <b-input-group>
                <b-select
                  id="holesCount"
                  v-model="holesCount"
                  :options="holesCountOptions"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('edgeDistanceEC.headlineDimensions') }}</h3>
        <div class="row">
          <div class="col-lg-6">
            <!-- Spannweite -->
            <b-form-group label-for="componentSpan">
              <template slot="label">
                {{ $t('edgeDistanceEC.componentSpan') }}
                <span class="text-muted">I</span>
                <b-button
                  id="popover-component-span"
                  variant="default"
                >
                  <font-awesome-icon icon="question-circle" />
                </b-button>
                <b-popover
                  target="popover-component-span"
                  triggers="hover"
                  placement="top"
                >
                  <p>{{ $t('edgeDistanceEC.componentSpanTooltip') }}</p>
                  <p v-html="$t('general.tooltipAdditionalInfo')"></p>
                </b-popover>
              </template>
              <b-input-group append="mm">
                <FormInputDecimal
                  id="componentSpan"
                  v-model="componentSpan"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-lg-6">
            <!-- Bautteillänge -->
            <b-form-group label-for="componentLength">
              <template slot="label">
                {{ $t('edgeDistanceEC.componentLength') }}
                <span class="text-muted">I<sub>n</sub></span>
              </template>
              <b-input-group append="mm">
                <FormInputDecimal
                  id="componentLength"
                  v-model="componentLength"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- Betongüte -->
            <b-form-group label-for="concreteQuality">
              <template slot="label">
                {{ $t('edgeDistanceEC.concreteQuality') }}
              </template>
              <b-input-group>
                <b-form-select
                  id="concreteQuality"
                  v-model="concreteQuality"
                  :options="concreteQualitySelectOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>
          a<sub>1</sub>
          <b-button
            id="popover-depth-supporting-a1"
            variant="link"
          >
            <font-awesome-icon icon="question-circle" />
          </b-button>
          <b-popover
            target="popover-depth-supporting-a1"
            triggers="hover"
            placement="top"
          >
            <p>{{ $t('edgeDistanceEC.supportBaseTooltip') }}</p>
            <p v-html="$t('general.tooltipAdditionalInfo')"></p>
          </b-popover>
        </h3>
        <div class="row">
          <div class="col-lg-6">
            <!-- Auflagerbauweise -->
            <b-form-group label-for="supportBaseType">
              <template slot="label">
                {{ $t('edgeDistanceEC.supportBaseType') }}
              </template>
              <b-input-group>
                <b-form-select
                  id="supportBaseType"
                  v-model="supportBaseType"
                  :options="supportBaseTypeSelectOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <h3>
              a<sub>2</sub>
              <b-button
                id="popover-depth-supporting-a2"
                variant="link"
              >
                <font-awesome-icon icon="question-circle" />
              </b-button>
              <b-popover
                target="popover-depth-supporting-a2"
                triggers="hover"
                placement="top"
              >
                <p>{{ $t('edgeDistanceEC.distanceSupportingTooltip') }}</p>
                <p v-html="$t('general.tooltipAdditionalInfo')"></p>
              </b-popover>
            </h3>
            <!-- Bauweise stützende Bauteil -->
            <b-form-group label-for="supportingBearingType">
              <template slot="label">
                {{ $t('edgeDistanceEC.supportingBearingType') }}
              </template>
              <b-input-group>
                <b-form-select
                  id="supportingBearingType"
                  v-model="supportingBearingType"
                  :options="supportingBearingTypeSelectOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-lg-6">
            <h3>
              &#916;
              <span class="text-muted">a<sub>2</sub></span>
              <b-button
                id="popover-depth-max-supporting-a2"
                variant="link"
              >
                <font-awesome-icon icon="question-circle" />
              </b-button>
              <b-popover
                target="popover-depth-max-supporting-a2"
                triggers="hover"
                placement="top"
              >
                <p>{{ $t('edgeDistanceEC.depthMaxSupportingTooltip') }}</p>
                <p v-html="$t('general.tooltipAdditionalInfo')"></p>
              </b-popover>
            </h3>
            <!-- Art stützende Bauteil -->
            <b-form-group label-for="maxSupportingBearingType">
              <template slot="label">
                {{ $t('edgeDistanceEC.maxSupportingBearingType') }}
              </template>
              <b-input-group>
                <b-form-select
                  id="supportingConstructionType"
                  v-model="maxSupportingBearingType"
                  :options="maxSupportingBearingTypeSelectOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <h3>
          a<sub>3</sub>
          <b-button
            id="popover-depth-supporting-a3"
            variant="link"
          >
            <font-awesome-icon icon="question-circle" />
          </b-button>
          <b-popover
            target="popover-depth-supporting-a3"
            triggers="hover"
            placement="top"
          >
            <p>{{ $t('edgeDistanceEC.distanceSupportedTooltip') }}</p>
            <p v-html="$t('general.tooltipAdditionalInfo')"></p>
          </b-popover>
        </h3>
        <div class="row">
          <div class="col-lg-6">
            <!-- Bewehrung stützende Bauteil -->
            <b-form-group label-for="supportedReinforcement">
              <template slot="label">
                {{ $t('edgeDistanceEC.supportedReinforcement') }}
              </template>
              <b-input-group>
                <b-form-select
                  id="supportedReinforcement"
                  v-model="supportedReinforcement"
                  :options="supportedReinforcementSelectOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-lg-6">
            <!-- Art des Lagers -->
            <b-form-group label-for="supportedBearingType">
              <template slot="label">
                {{ $t('edgeDistanceEC.supportedBearingType') }}
              </template>
              <b-input-group>
                <b-form-select
                  id="supportedBearingType"
                  v-model="supportedBearingType"
                  :options="supportedBearingTypeSelectOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="panel">
        <h3>{{ $t('bearings.headlineKeyFigures') }}</h3>
        <div class="table-responsive">
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>
                  {{ $t('edgeDistanceEC.effects') }}
                  <span class="text-muted">&sigma;<sub>Ed</sub></span>
                </td>
                <td class="text-right">
                  {{ compressiveStress | numeralFormat('0.0') }} N/mm²
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('edgeDistanceEC.concretePressure') }}
                  <span class="text-muted">f<sub>cd</sub></span>
                </td>
                <td class="text-right">
                  {{ concretePressure | numeralFormat('0.0') }} N/mm²
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('edgeDistanceEC.bearingSqueeze') }}
                  <span class="text-muted">&sigma;<sub>Ed</sub> / f<sub>cd</sub></span>
                </td>
                <td class="text-right">
                  {{ bearingSqueeze | numeralFormat('0.00') }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('edgeDistanceEC.depthParValue') }} <span class="text-muted">a</span>
                </td>
                <td class="text-right">
                  <span v-if="!warningsA2.status && !warningsA3.status">
                    {{ depthParValue | numeralFormat('0.0') }} mm
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-muted">a<sub>1</sub></span>
                </td>
                <td class="text-right">
                  {{ depthBase | numeralFormat('0') }} mm
                </td>
              </tr>
              <tr :class="a2Status">
                <td>
                  <span class="text-muted">a<sub>2</sub></span>
                </td>
                <td
                  v-if="!warningsA2.status"
                  class="text-right"
                >
                  {{ distanceSupporting | numeralFormat('0') }} mm
                </td>
                <td
                  v-if="warningsA2.status"
                  class="text-right"
                >
                  <span
                    v-for="item in warningsA2.msg"
                    :key="item"
                    class="badge badge-danger"
                    :inner-html.prop="item | abbr"
                  >
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  &#916;
                  <span class="text-muted">a<sub>2</sub></span>
                </td>
                <td class="text-right">
                  {{ maxDistanceSupporting | numeralFormat('0.00') }} mm
                </td>
              </tr>
              <tr>
                <td>
                  a<sub>2</sub> + &#916;a<sub>2</sub>
                </td>
                <td class="text-right">
                  <span v-if="!warningsA2.status">
                    {{ sumDistanceSupporting | numeralFormat('0.00') }} mm
                  </span>
                  <span v-else>-</span>
                </td>
              </tr>
              <tr :class="a3Status">
                <td>
                  <span class="text-muted">a<sub>3</sub></span>
                </td>
                <td
                  v-if="!warningsA3.status"
                  class="text-right"
                >
                  {{ distanceSupported | numeralFormat('0') }} mm
                </td>
                <td
                  v-if="warningsA3.status"
                  class="text-right"
                >
                  <span
                    v-for="item in warningsA3.msg"
                    :key="item"
                    class="badge badge-danger"
                    :inner-html.prop="item | abbr"
                  >
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  &#916;
                  <span class="text-muted">a<sub>3</sub></span>
                  <b-button
                    id="popover-length-max-supported-a3"
                    variant="link"
                  >
                    <font-awesome-icon icon="question-circle" />
                  </b-button>
                  <b-popover
                    target="popover-length-max-supported-a3"
                    triggers="hover"
                    placement="top"
                  >
                    <p>{{ $t('edgeDistanceEC.lengthMaxSupportedTooltip') }}</p>
                    <p v-html="$t('general.tooltipAdditionalInfo')"></p>
                  </b-popover>
                </td>
                <td class="text-right">
                  {{ maxDistanceSupported | numeralFormat('0.00') }} mm
                </td>
              </tr>
              <tr>
                <td>
                  a<sub>3</sub> + &#916;a<sub>3</sub>
                </td>
                <td class="text-right">
                  <span v-if="!warningsA3.status">
                    {{ sumDistanceSupported | numeralFormat('0.00') }} mm
                  </span>
                  <span v-else>-</span>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    {{ $t('edgeDistanceEC.edgeDistance') }}
                  </strong>
                </td>
                <td class="text-right">
                  <span v-if="!warningsA2.status && !warningsA3.status">
                    <strong>
                      {{ edgeDistance | numeralFormat('0.0') }} mm
                    </strong>
                  </span>
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('edgeDistanceEC.headlineIllustration') }}</h3>
        <div class="row">
          <img
            src="../../assets/img/abbildungen/randabstand-EC2-draufsicht.jpg"
            :alt="$t('edgeDistanceEC.headlineIllustration')"
          />
          <img
            src="../../assets/img/abbildungen/randabstand-EC2-ansicht.jpg"
            :alt="$t('edgeDistanceEC.headlineIllustration')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputDecimal from '../app/form/FormInputDecimal.vue';
import FormInputInteger from '../app/form/FormInputInteger.vue';
import DisabledInputs from '../../mixins/DisabledInputs';
import CalcToolsEdgeDistanceEC from '../../helpers/CalcToolsEdgeDistanceEC';

export default {
  components: {
    FormInputDecimal,
    FormInputInteger,
  },
  mixins: [DisabledInputs],
  props: {
    propPower: {
      type: Number,
      default: 400,
    },
    propBearingWidth: {
      type: Number,
      default: 280,
    },
    propBearingLength: {
      type: Number,
      default: 400,
    },
    propBearingThickness: {
      type: Number,
      default: 20,
    },
    propHolesCount: {
      type: Number,
      default: 0,
    },
    propHolesDiameter: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      bearingThicknessOptions: this.$dataOptionsHelper.getBearingThicknessOptions(),
      bearingThicknessSelectOptions:
        Object.values(this.$dataOptionsHelper.getBearingThicknessOptions()),
      power: this.propPower,
      bearingWidth: this.propBearingWidth,
      bearingLength: this.propBearingLength,
      bearingThickness: this.propBearingThickness,
      holesCount: this.propHolesCount,
      holesDiameter: this.propHolesDiameter,
      concreteQuality: 'C12/15',
      componentSpan: 6000,
      componentLength: 5000,
      holesCountOptions: this.$dataOptionsHelper.getBearingHolesOptions(),
      concreteQualityOptions: this.$dataOptionsHelper.getConcreteQualityOptions(),
      concreteQualitySelectOptions:
        Object.values(this.$dataOptionsHelper.getConcreteQualityOptions()),
      supportBaseType: 'blanket',
      supportingBearingType: 'line',
      supportedReinforcement: 'continuousBars',
      supportedBearingType: 'line',
      supportedBearingTypeOptions:
        this.$dataOptionsHelper.getSupportedBearingTypeOptions(this.$i18n),
      supportedBearingTypeSelectOptions:
        Object.values(this.$dataOptionsHelper.getSupportedBearingTypeOptions(this.$i18n)),
      maxSupportingBearingType: 'concreteSteel',
      maxSupportingBearingTypeOptions:
        this.$dataOptionsHelper.getMaxSupportingTypeOptions(this.$i18n),
      maxSupportingBearingTypeSelectOptions:
        Object.values(this.$dataOptionsHelper.getMaxSupportingTypeOptions(this.$i18n)),
    };
  },
  computed: {
    a2Status() {
      return {
        'table-danger text-danger': this.warningsA2.status,
      };
    },
    warningsA2() {
      const error = {
        status: false,
        msg: [],
      };
      if (
        this.bearingSqueeze > 0.4
        && (
          this.supportingBearingType === 'supportingBearingTypeOptionMasonryLine'
          || this.supportingBearingType === 'supportingBearingTypeOptionMasonrySingle'
        )
      ) {
        error.status = true;
        error.msg.push(this.$i18n.t('edgeDistanceEC.warningA2ConcreteStone'));
      }
      return error;
    },
    a3Status() {
      return {
        'table-danger text-danger': this.warningsA3.status,
      };
    },
    warningsA3() {
      const error = {
        status: false,
        msg: [],
      };
      if (
        this.supportedBearingType === 'single'
        && (
          this.supportedReinforcement === 'straightBars'
          || this.supportedReinforcement === 'verticalReinforcement'
        )
      ) {
        if (this.supportedReinforcement === 'straightBars') {
          error.status = true;
          error.msg.push(this.$i18n.t('edgeDistanceEC.warningA3EndConcrete'));
        }
        if (this.supportedReinforcement === 'verticalReinforcement') {
          error.status = true;
          error.msg.push(this.$i18n.t('edgeDistanceEC.warningA3EndConcreteRadius'));
        }
      }
      return error;
    },
    supportBaseTypeOptions() {
      return this.$dataOptionsHelper.getSupportBaseTypeOptions(this.$i18n, this.bearingSqueeze);
    },
    supportingBearingTypeOptions() {
      return this.$dataOptionsHelper.getSupportingBearingTypeOptions(
        this.$i18n,
        this.bearingSqueeze,
      );
    },
    supportedReinforcementOptions() {
      return this.$dataOptionsHelper.getReinforcementOptions(this.$i18n, this.supportedBearingType);
    },
    supportBaseTypeSelectOptions() {
      return Object.values(
        this.$dataOptionsHelper.getSupportBaseTypeOptions(this.$i18n, this.bearingSqueeze),
      );
    },
    supportingBearingTypeSelectOptions() {
      return Object.values(this.$dataOptionsHelper.getSupportingBearingTypeOptions(
        this.$i18n,
        this.bearingSqueeze,
      ));
    },
    supportedReinforcementSelectOptions() {
      return Object.values(
        this.$dataOptionsHelper.getReinforcementOptions(this.$i18n, this.supportedBearingType),
      );
    },
    depthBase() {
      return this.supportBaseTypeOptions[this.supportBaseType].distance;
    },
    distanceSupporting() {
      return this.supportingBearingTypeOptions[this.supportingBearingType].distance;
    },
    maxDistanceSupporting() {
      return this.maxSupportingBearingTypeOptions[this.maxSupportingBearingType].distance;
    },
    sumDistanceSupporting() {
      return this.$outputHelper.convertToFloat(this.distanceSupporting, 1)
        + this.$outputHelper.convertToFloat(this.maxDistanceSupporting, 1);
    },
    distanceSupported() {
      return this.supportedReinforcementOptions[this.supportedReinforcement].distance;
    },
    maxDistanceSupported() {
      return this.$outputHelper.convertToFloat(this.componentLength, 2) / 2500;
    },
    sumDistanceSupported() {
      return this.$outputHelper.convertToFloat(this.distanceSupported, 1)
        + this.$outputHelper.convertToFloat(this.maxDistanceSupported, 1);
    },
    concretePressure() {
      return this.concreteQualityOptions[this.concreteQuality].factor;
    },
    bearingSqueeze() {
      return this.$outputHelper.convertToFloat(this.compressiveStress, 1)
        / this.$outputHelper.convertToInteger(this.concretePressure);
    },
    compressiveStress() {
      return this.$calcHelper.getCompressiveStress(
        this.power,
        this.areaNetto,
      );
    },
    areaNetto() {
      return this.$calcHelper.getAreaNetto(
        this.bearingWidth,
        this.bearingLength,
        this.holesCount,
        this.holesDiameter,
      );
    },
    depthParValue() {
      return CalcToolsEdgeDistanceEC.getDepthParValue(
        this.depthBase,
        this.distanceSupporting,
        this.distanceSupported,
        this.maxDistanceSupporting,
        this.maxDistanceSupported,
      );
    },
    edgeDistance() {
      return CalcToolsEdgeDistanceEC.getEdgeDistance(
        this.distanceSupporting,
        this.distanceSupported,
        this.maxDistanceSupporting,
        this.maxDistanceSupported,
      );
    },
  },
};
</script>

<style scoped lang="scss">

</style>
