import OutputHelper from './OutputHelper';

export default {
  // Nennwert der Auflagertiefe a
  getDepthParValue(a1, a2, a3, deltaA2, deltaA3) {
    const a1Casted = OutputHelper.convertToInteger(a1);
    const a2Casted = OutputHelper.convertToInteger(a2);
    const a3Casted = OutputHelper.convertToInteger(a3);
    const deltaA2Casted = OutputHelper.convertToNumber(deltaA2);
    const deltaA3Casted = OutputHelper.convertToNumber(deltaA3);

    return a1Casted + a2Casted + a3Casted + Math.sqrt((deltaA2Casted ** 2) + (deltaA3Casted ** 2));
  },

  // Randabstand
  getEdgeDistance(a2, a3, deltaA2, deltaA3) {
    const a2Casted = OutputHelper.convertToInteger(a2);
    const a3Casted = OutputHelper.convertToInteger(a3);
    const deltaA2Casted = OutputHelper.convertToNumber(deltaA2);
    const deltaA3Casted = OutputHelper.convertToNumber(deltaA3);

    return a2Casted + a3Casted + Math.sqrt((deltaA2Casted ** 2) + (deltaA3Casted ** 2));
  },
};
